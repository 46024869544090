import { FeedbackDto } from '@portals/shared/admin/FeedbackDto';

import { request } from './request';

export async function fetchFeedback(): Promise<FeedbackDto[]> {
  const response = await request<FeedbackDto[]>({
    url: 'feedback',
  });
  return response.data;
}
