




























































import { FeedbackDto } from '@portals/shared/admin/FeedbackDto';
import { format } from 'date-fns';
import Vue from 'vue';

import AdminPopUp from '@/components/AdminPopUp.vue';
import DnbButton from '@/components/DnbButton.vue';
import DnbIcon from '@/components/DnbIcon.vue';
import Loader from '@/components/Loader.vue';
import { fetchFeedback } from '@/service/feedbackService';

interface Data {
  feedback?: FeedbackDto[];
  selected?: FeedbackDto;
}

export default Vue.extend({
  components: {
    Loader,
    DnbIcon,
    AdminPopUp,
    DnbButton,
  },
  data(): Data {
    return {
      feedback: undefined,
      selected: undefined,
    };
  },
  async mounted() {
    const feedback = await fetchFeedback();
    this.feedback = feedback;
  },
  methods: {
    dateFormatter(date: string) {
      if (!date) return 'N/A';
      return format(date, 'DD.MM.YYYY HH:mm');
    },
    iconClass(message: FeedbackDto): Record<string, boolean> {
      return { [this.$style.thumbsDown]: !message.helpful };
    },
    helpfulFormatter(helpful: boolean) {
      return helpful ? 'Helpful' : 'Not helpful';
    },
    open(message: FeedbackDto) {
      this.selected = message;
    },
    close() {
      this.selected = undefined;
    },
  },
});
